footer {
    width: 100%;
    height: 220px;
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color: rgb(0, 115, 255);
    margin: auto;
    margin-top: 20px;
    text-align: center;
    font-size: larger;

    @media only screen and (min-width : $breakpoint_xl) and (max-width : $breakpoint_xxl) {
      font-size: 1.2em;
    }
   
    @media only screen and (min-width : $breakpoint_md) and (max-width : $breakpoint_xl) {
      font-size: 1em;
      height: 170px;
    }

    @media only screen and (min-width : $breakpoint_sm) and (max-width : $breakpoint_md) {
      font-size: 1em;
      height: auto;
    }

    @media only screen and (max-width : $breakpoint_sm) {
       font-size: 1em;
       height: auto;
   }
      
    .foot1-div {
       flex-basis: 35%;
       position: relative;
       height: 90%;
       margin: auto;
       font-weight: 700;
       padding-top: 1%;

       @media only screen and (min-width : $breakpoint_xl) and (max-width : $breakpoint_xxl) {

      }
      @media only screen and (min-width : $breakpoint_md) and (max-width : $breakpoint_xl) {
        
      }
      @media only screen and  (min-width : $breakpoint_sm) and (max-width : $breakpoint_md) {
        flex-basis: 45%;
      }
      
      .inner1 {
        position: absolute;
        width: 100%;
        height: 20px;
        top: 0;
        border-bottom: solid #2abbdf 3px;
        color: white;
        line-height: 100%;
        margin-top: 10px;
      }
      .inner2 {
        position: absolute;
        width: 100%;
        height: 20px;
        top: 0;
        border-bottom: solid #2abbdf 3px;
        color: white;
        line-height: 100%;
        margin-top: 10px;
        @media only screen and  (min-width : $breakpoint_sm) and (max-width : $breakpoint_md) {
          top: -13.5%;
        }
        @media only screen and (max-width : $breakpoint_sm) {
          top: -3.5%;
      }
      }

      ul {
        text-align: left;
        margin-top: 35px;
        margin-left: -5%;
       
        .li-f1 {
          position: relative;
          list-style: none;
          margin-top: 10px;
          color: #ffffff;
          span {
            position: absolute;
            left: 50px;

            @media only screen and (max-width : $breakpoint_sm) {
            left: 30px;
        }
       
          }
          .sp1 {
            @media only screen and (max-width : $breakpoint_xl) {
              top: 5px;
          }
          }
          
        }
        .li-f2 {
          margin-top: 15px;
          color: #ffffff;
          list-style: none;
        }
        @media only screen and (min-width : $breakpoint_xl) and (max-width : $breakpoint_xxl) {
          margin-left: -10%;
        }
        @media only screen and (min-width : $breakpoint_md) and (max-width : $breakpoint_xl) {
          margin-left: -10%;
        }
        @media only screen and (min-width : $breakpoint_sm) and (max-width : $breakpoint_md) {
          margin-left: -12%;
        }
        @media only screen and (max-width : $breakpoint_sm) {
          margin-left: -23%;
      }
      }

      
    }

    .foot2-div {
      flex-basis: 20%;
      padding-right: 1%;
      padding-top: 0;
      img {
        width: 100%;
        margin-right: 2%;
      }
      @media only screen and (min-width : $breakpoint_xl) and (max-width : $breakpoint_xxl) {
        margin-top: 4%;
      }
      @media only screen and (min-width : $breakpoint_md) and (max-width : $breakpoint_xl) {
        margin-top: 3%;
      }
      @media only screen and (max-width : $breakpoint_md) {
        flex-basis: 0;
        display: none;
    }

    }
  }