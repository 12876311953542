.header {
    
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background-color: rgb(0, 115, 255);
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, .5), inset 1px 0 1px rgba(255, 255, 255, 0.5);
    color: #ffffff;
    
    @media only screen and (max-width : $breakpoint_sm) {
      position: relative;
      padding-left: 3%;
    }

   .h-phone {
    flex-basis: 30%;
    height: 150px;
    margin: 0 1%;
    
    @media only screen and (max-width : $breakpoint_sm) {
      position: absolute;
      height: auto;
      width: 90%;
      top: 22%;
      font-size: 1em;
    }

    label {
      display: block;
      margin-top: 8%;
      margin-left: 10%;
      width: 80%;
      line-height: 70px;
      font-weight: 700;
      font-size: 1.4em;
      border-radius: 5px;
      box-shadow: 3px 7px 10px rgb(0, 0, 0, .5), inset 1px 0 1px rgba(255, 255, 255, 0.5);
      @media only screen and (max-width : $breakpoint_sm) {
        line-height: 50px;
        margin-top: 12%;
      }

    }

    @media only screen and (min-width : $breakpoint_xl) and (max-width : $breakpoint_xxl) {
      font-size: 1em;
      margin-top: 1%;
    }
    @media only screen and (min-width : $breakpoint_md) and (max-width : $breakpoint_xl) {
      flex-basis: 39%;
      font-size: 1em;
      margin-top: 1.5%;
    }
    @media only screen and (min-width : $breakpoint_sm) and (max-width : $breakpoint_md) {
      flex-basis: 39%;
      font-size: 1em;
      margin-top: 3%;
    }

   } 
    
    .h-center{
      flex-basis: 40%;
      margin: 0 1%;

      @media only screen and (max-width : $breakpoint_sm) {
        position: absolute;
        height: auto;
        width: 90%;
        font-size: 1.2em;
      }
      
      h1 {
      margin-top: 1%;
      margin-bottom: -3%;
      font-size: 3em;
      @media only screen and (min-width : $breakpoint_md) and (max-width : $breakpoint_xxl) {
        font-size: 2em;
        }
        @media only screen and (min-width : $breakpoint_sm) and (max-width : $breakpoint_md) {
          margin-bottom: -5%;
        }
      }
      #p1 {
      font-size: 200%;
      @media only screen and (max-width : $breakpoint_sm) {
        display: none;
      }
      }

      @media only screen and (max-width : $breakpoint_xl) {
        flex-basis: 60%;
        margin-left: 1%;
      }
      @media only screen and (min-width : $breakpoint_sm) and (max-width : $breakpoint_md) {
        font-size: 1.2em;
      }
      
    }

    .h-adress {
      flex-basis: 25%;
      margin: 1% 1%;
      font-size: 1.7em;
      line-height: 15px;
      @media only screen and (min-width : $breakpoint_xl) and (max-width : $breakpoint_xxl) {
        font-size: 1.4em;
      }
      
      @media only screen and (max-width : $breakpoint_xl) {
        flex-basis: 0;
        display: none;
      }
    }
  
  }